// Always show a scrollbar on the side of the page (so the page doesn't jump around)
body {
  overflow-y: scroll;
}

// Add a text-secondary gradient for text
.text-gradient.text-secondary {
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
}

// Set the style of the small arrow (or other symbol) on top of the dropdown menu
// NOTE: Remember to check the dropdown menu on iPad when modifying these things. For some reason it often has
//       some issues there (e.g., extra padding above the dropdown items in the dropdown menus).
.dropdown {
  .dropdown-menu {
    &:before {
      // Choose the symbol you want to use (default is https://fontawesome.com/icons/caret-up?s=solid)
      //font-family: "FontAwesome";
      //content: "\f0d8";
      // Set the color the same as the navbar and dropdown-menu
      //color: rgba(255, 255, 255, 1);
      // By default, the symbol fades in/out and is shifted up/down when the dropdown menu is open/closed. The up/down
      // shifting looks annoying, so we set the start position of the symbol to be the same as the end position. Now
      // it only fades in/out.
      top: $dropdown-hover-arrow-active-top;
    }
  }
}

// Set the height of the dropdown menu on mobile to auto. Before it was fixed to 250px, which made it much too big when
// the dropdown menu only had a few items.
.dropdown.nav-item .dropdown-menu-animation {
  &.show {
    height: auto;
  }
}

// Set the navbar and dropdown-menu colors. The original theme has blurred navbar but solid white dropdown-menu. It
// looks better when they are the same. The 'backdrop-filter' implementation is buggy in various browsers (and
// has to be manually enabled in FireFox to work), so the way the blurring was done results in odd errors depending
// on the browser. If the backdrop-filter is enabled in both the navbar and dropdown-menu, both should be blurred in
// Safari (though I have not tested this), and none should be blurred in FireFox. In Chrome, only the navbar would be
// blurred, because there is a conflict when a parent and child element both have backdrop-filter enabled.
// Read more at: https://generatepress.com/forums/topic/blur-filter-opacity-behind-drop-down-menu-not-working/
//               https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome
//               https://bugs.chromium.org/p/chromium/issues/detail?id=993971
.navbar {
  //-webkit-backdrop-filter: saturate(200%) blur(30px);
  //backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 30px;
}
.dropdown-menu {
  background-color: rgba(255, 255, 255, 1) !important;
}

// We want the image on the front page (the one with the waves that is at the very top) to have different heights
// depending on the screen size. The original theme uses .min-vh-100, which was too tall on mobile.
.min-vh-95 {
  min-height: 95vh !important;
}
@media (min-width: 1200px) {
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
}

// We want Bootstrap popovers to have slightly larger text size
.popover-header {
      font-size: 1rem;
  }
.popover-body {
    font-size: 0.9rem;
}