//
// Badge floating
//

.btn {
  .badge-floating {
    position: absolute;
    top: -$badge-floating-top;
    transform: translateY($badge-floating-top);
    border: $badge-floating-border solid;

    &.badge:not(.badge-circle) {
      transform: $badge-floating-transform;
    }
  }
}
