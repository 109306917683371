#completed-tasks label {
  text-decoration: line-through;
  color: #888;
}

/* Edit Task */
ul li input[type="text"] {
  display: none;
}

ul li.editMode input[type="text"] {
  display: block;
}

ul li.editMode label {
  display: none;
}
