// Pagination

$pagination-active-box-shadow: $btn-hover-box-shadow !default;

$page-link-display: flex !default;
$page-link-align-items: center !default;
$page-link-justify-content: $page-link-align-items !default;
$page-link-margin: 0 3px !default;
$page-link-radius: 50% !default;
$page-link-width: 36px !default;
$page-link-height: $page-link-width !default;

$page-link-width-lg: 46px !default;
$page-link-height-lg: $page-link-width-lg !default;
$page-link-line-height-lg: $page-link-width-lg !default;

$page-link-width-sm: 30px !default;
$page-link-height-sm: $page-link-width-sm !default;
$page-link-line-height-sm: $page-link-width-sm !default;
