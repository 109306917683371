@each $prop, $value in $theme-gradient-colors {
  .bg-gradient-#{$prop} {
    @include gradient-directional(
      nth($value, 1) 0%,
      nth($value, -1) 100%,
      $deg: 310deg
    );
  }
}

@each $prop, $value in $theme-colors {
  .bg-gradient-faded-#{$prop} {
    background-image: radial-gradient(
      370px circle at 80% 50%,
      rgba($value, 0.6) 0,
      darken($value, 10%) 100%
    );
  }
}

@each $prop, $value in $theme-colors {
  &.bg-gradient-faded-#{$prop}-vertical {
    background-image: radial-gradient(
      200px circle at 50% 70%,
      rgba($value, 0.3) 0,
      $value 100%
    );
  }
}
