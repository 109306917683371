$dropdown-subitem-position: 101% !default;
$dropdown-transition: visibility 0.25s, opacity 0.25s, transform 0.25s !default;
$dropdown-transform-origin: 50% 0 !default;
$dropdown-transform: perspective(999px) rotateX(-10deg) translateZ(0)
  translate3d(0px, 37px, 0px) !important !default;
$dropdown-transform-show: perspective(999px) rotateX(0deg) translateZ(0)
  translate3d(0, 37px, 5px) !important !default;

$dropdown-multilevel-transform: perspective(999px) rotateX(-10deg) translateZ(0)
  translate3d(0px, 0px, 0px) !important !default;
$dropdown-multilevel-transform-show: perspective(999px) rotateX(0deg)
  translateZ(0) translate3d(0, 0px, 5px) !important !default;

$dropup-transform-origin: bottom !default;
$dropup-transform: perspective(999px) rotateX(12deg) translateZ(0)
  translate3d(0px, 0px, 0px) !important !default;
$dropup-transform-show: perspective(999px) rotateX(0deg) translateZ(0)
  translate3d(1px, 0px, 5px) !important !default;

$dropdown-icon-margin-right: 0.75rem !default;

$dropdown-toggle-arrow-transform: rotate(180deg) !default;
$dropdown-toggle-arrow-transition: 0.3s ease !default;

$dropdown-button-after-margin-top: 3px !default;

$dropdown-animation-arrow-left-position: 28px !default;
$dropup-animation-arrow-bottom-position: 22px !default;
$dropdown-animation-arrow-font-size: 22px !default;
$dropup-animation-arrow-transition: bottom 0.35s ease !default;
$dropdown-animation-arrow-transition: top 0.35s ease !default;
$dropup-mb: 0.5rem !default;

$dropdown-mt: 8px !default;
$dropdown-hover-after-bottom-pos: -24px !default;
$dropdown-hover-after-bottom-pos: -24px !default;
$dropdown-hover-arrow-active-top: -20px !default;

$dropdown-subitem-position-right: -197px !default;
$dropdown-subitem-left-hover: -8px !default;
$dropdown-subitem-arrow-rotate: rotate(-90deg) !default;

$dropdown-md-min-width: 15rem !default;
$dropdown-lg-min-width: 23rem !default;
$dropdown-lg-width-responsive: 19rem !default;
$dropdown-xl-min-width: 40rem !default;

$dropdown-subitem-position: 101% !default;

// Extend
$dropdown-subitem-position-right: -197px !default;
$dropdown-subitem-left-hover: -8px !default;
$dropdown-subitem-arrow-rotate: rotate(-90deg) !default;

$dropdown-md-min-width: 15rem !default;
$dropdown-lg-min-width: 23rem !default;
$dropdown-lg-width-responsive: 19rem !default;
$dropdown-xl-min-width: 40rem !default;
