.form-control {
  &.is-invalid {
    &:focus {
      box-shadow: 0 0 0 2px rgba($form-feedback-invalid-color, 0.6);
    }
  }

  &.is-valid {
    &:focus {
      box-shadow: 0 0 0 2px rgba($form-feedback-valid-color, 0.65);
    }
  }
}
