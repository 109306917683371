/*!
FullCalendar v5.6.0
Docs & License: https://fullcalendar.io/
(c) 2020 Adam Shaw
*/

// Creative Tim Changes

.fc-theme-standard .fc-scrollgrid,
.fc-scrollgrid {
  border: none;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: $gray-200;
}

.fc-theme-standard thead tr th {
  border-right: none;
  border-left: none;
}

.fc-theme-standard td {
  &:last-child {
    border-right: none;
  }
}

.fc-theme-standard tr {
  &:last-child td {
    border-bottom: none;
  }
}

.fc-h-event {
  border: none;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  font-weight: $font-weight-bold;
  padding: $fc-event-title-padding-y $fc-event-title-padding-x;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0;
}

.fc .fc-col-header-cell-cushion {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $gray-500;
}

.fc .fc-daygrid-day-number {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  width: 100%;
  text-align: center;
}

.fc .fc-toolbar-title {
  font-size: $font-size-lg;
}

.fc .fc-button {
  font-size: $font-size-sm;
  padding-top: $spacer/4;
  box-shadow: $btn-box-shadow;
  transition: $btn-transition;
  .fc-icon {
    font-size: $font-size-sm;
  }
}

.fc .fc-button-primary {
  background-color: $secondary;
  border-color: $secondary;
  &:hover,
  &:focus,
  &:not(:disabled):active,
  &:not(:disabled):active:focus {
    transform: $btn-hover-transform;
    box-shadow: $btn-hover-box-shadow;
    background-color: $secondary;
    border-color: $secondary;
  }
}

.fc-event.fc-daygrid-event {
  border-radius: $fc-daygrid-event-border-radius;
}
