// Customize the width of the dropdown menu in the navbar on the homepage
$dropdown-min-width: 6rem !default;
$dropdown-md-min-width: 8rem !default;

// Accordion color changed to grey after pressing it on/off in FAQ section on homepage, so change to keep the same color
$accordion-color: #344767 !default;

// Add new breakpoints (so we can, e.g., use col-uhd-6 to get 50% width on 4K screens)
@import "soft-design-system-pro/variables";
$grid-breakpoints: map-merge($grid-breakpoints, (
  qhd: 1920px,
  uhd: 2560px
));
