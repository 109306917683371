$choices-box-shadow: $dropdown-box-shadow !default;
$choices-border-radius: 0.5rem !default;
$choices-animation: 0.3s cubic-bezier(0.23, 1, 0.32, 1) !default;
$choices-transition: $dropdown-transition !default;
$choices-transform: perspective(999px) rotateX(-10deg) translateZ(0)
  translate3d(0px, 37px, 0px) !important !default;
$choices-transform-show: perspective(999px) rotateX(0deg) translateZ(0)
  translate3d(0, 37px, 5px) !important !default;
$choices-padding-y: 1rem !default;
$choices-padding-x: 0.5rem !default;
