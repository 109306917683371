$card-box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05) !default;
$card-background-blur: rgba(255, 255, 255, 0.8) !default;

$card-header-padding: 1.5rem !default;

$card-body-padding: $card-header-padding !default;
$card-plain-bg-color: transparent !default;
$card-plain-box-shadow: none !default;

$card-footer-padding: $card-body-padding !default;

$card-title-font-weight: $font-weight-bold !default;
$card-title-line-height: 1.2 !default;

$card-author-display: flex !default;
$card-author-name-line-height: 1.571 !default;
$card-author-name-color: #3a416f !default;
$card-author-stats-color: #5d6494 !default;
$card-avatar-width: 30px !default;
$card-avatar-height: $card-avatar-width !default;
$card-avatar-overflow: hidden !default;
$card-avatar-radius: 50% !default;
$card-avatar-mr: 5px !default;
$card-stats-name-mt: -4px !default;
$card-name-ml: 0.25rem !default;

$card-badge-text-transform: uppercase !default;
$card-badge-bg-color: #fafaff !default;

// Card Background
$card-bg-align-items: center !default;
$card-bg-body-position: relative !default;
$card-bg-body-z-index: 2 !default;
$card-bg-content-min-height: 330px !default;
$card-bg-content-max-width: 450px !default;
$card-bg-content-pt: 60px !default;
$card-bg-content-pb: $card-bg-content-pt !default;
$card-bg-body-left-width: 90% !default;

$card-bg-description-margin: 24px !default;

$card-bg-filter-position: absolute !default;
$card-bg-filter-top: 0 !default;
$card-bg-filter-bottom: $card-bg-filter-top !default;
$card-bg-filter-left: $card-bg-filter-top !default;
$card-bg-filter-height: 100% !default;
$card-bg-filter-width: $card-bg-filter-height !default;
$card-bg-filter-z-index: 1 !default;
$card-bg-filter-display: block !default;
$card-bg-filter-content: "" !default;
$card-bg-filter-bg: rgba(0, 0, 0, 0.4) !default;
$card-bg-filter-mask-bg: rgba(0, 0, 0, 0.2) !default;
$card-bg-filter-radius: 1rem !default;

$card-full-bg-bg-position: 50% !default;
$card-full-bg-bg-size: cover !default;
$card-full-bg-mb: 30px !default;
$card-full-bg-width: 100% !default;
$card-full-bg-height: $card-full-bg-width !default;
$card-full-bg-position: absolute !default;
$card-full-bg-radius: $card-bg-filter-radius !default;
