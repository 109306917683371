/*!
Simple-DataTables
Github: https://github.com/fiduswriter/Simple-DataTables
*/

// Creative Tim Changes

.dataTable-wrapper {
  .dataTable-top {
    padding: $card-header-padding;
    .dataTable-dropdown {
      float: left;
      label {
        margin-bottom: 0;
        margin-left: 0;
        color: $secondary;
        font-weight: $font-weight-normal;
        .dataTable-selector {
          border-color: $gray-200;
          border-radius: $border-radius-sm;
        }
      }
    }
    .dataTable-search {
      input {
        font-size: $font-size-sm;
        color: $input-color;
        border: 1px solid $gray-200;
        @include border-radius($input-border-radius, 0);
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .dataTable-container {
    .table {
      thead {
        tr {
          th {
            padding: $table-head-spacer-y $table-head-spacer-x;
            opacity: 0.7;
            font-weight: bolder;
            color: $secondary;
            text-transform: uppercase;
            font-size: $font-size-xxs;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: $table-head-spacer-y $table-head-spacer-x;
          }
        }
      }
    }
  }
  .dataTable-bottom {
    padding: $card-header-padding;
    padding-top: 0;
    .dataTable-info {
      color: $secondary;
      font-size: $font-size-sm;
    }
    .dataTable-pagination {
      .dataTable-pagination-list {
        li {
          a {
            display: $page-link-display;
            align-items: $page-link-align-items;
            justify-content: $page-link-justify-content;
            color: $secondary;
            padding: 0;
            margin: $page-link-margin;
            border: 1px solid $gray-300;
            border-radius: $page-link-radius !important;
            width: $page-link-width;
            height: $page-link-height;
            font-size: $font-size-sm;
            margin-left: 0;
            &:hover {
              background: $gray-200;
            }
          }
        }
        .active {
          a {
            background: transparent;
            @include gradient-directional(
              nth($primary-gradient, 1) 0%,
              nth($primary-gradient-state, -1) 100%,
              $deg: 310deg
            );
            box-shadow: $pagination-active-box-shadow;
            color: $white;
            border: none;
            border-radius: $page-link-radius !important;
            &:hover {
              @include gradient-directional(
                nth($primary-gradient, 1) 0%,
                nth($primary-gradient-state, -1) 100%,
                $deg: 310deg
              );
            }
          }
        }
      }
    }
  }
  &.no-footer {
    .dataTable-container {
      border-bottom: 0;
    }
  }
}

.dataTable-table {
  thead,
  tbody,
  tfoot {
    td,
    th {
      vertical-align: middle !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dataTable-top > div:last-child {
    float: left;
    margin-top: $spacer;
  }
}
