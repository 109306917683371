// Waves Animation & Styling

$waves-position: relative !default;
$waves-width: 100% !default;
$waves-height: 16vh !default;
$waves-min-height: 100px !default;
$waves-max-height: 150px !default;
$waves-margin-bottom: -7px !default;
$waves-height-sm: 50px !default;
$waves-min-height-sm: $waves-height-sm !default;
$waves-rotate: rotate(180deg) !default;
$waves-mobile-height: 40px !default;

$moving-waves-keyframe-0: translate3d(-90px, 0, 0) !default;
$moving-waves-keyframe-100: translate3d(85px, 0, 0) !default;
$moving-waves-animation: move-forever 40s cubic-bezier(0.55, 0.5, 0.45, 0.5)
  infinite !default;
$moving-waves-child-1-delay: -2s !default;
$moving-waves-child-1-duration: 11s !default;
$moving-waves-child-2-delay: -4s !default;
$moving-waves-child-2-duration: 13s !default;
$moving-waves-child-3-delay: -3s !default;
$moving-waves-child-3-duration: 15s !default;
$moving-waves-child-4-delay: -4s !default;
$moving-waves-child-4-duration: 20s !default;
$moving-waves-child-5-delay: -4s !default;
$moving-waves-child-5-duration: 25s !default;
$moving-waves-child-6-delay: -3s !default;
$moving-waves-child-6-duration: 30s !default;

// Fade In

$fade-in-animation-name: fadeInBottom !default;
$fade-in-animation-top-name: fadeInTop !default;
$fade-in-bottom-transform: translateY(100%) !default;
$fade-in-top-transform: translateY(-100%) !default;

$fade-in-1-animation-duration: 1.5s !default;
$fade-in-2-animation-duration: 1.75s !default;
$fade-in-3-animation-duration: 2s !default;
$fade-in-4-animation-duration: 2.25s !default;
$fade-in-5-animation-duration: 2.5s !default;

$floating-man-width: 350px !default;
