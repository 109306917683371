.swal2-popup {
  border-radius: $card-border-radius;
}

.swal2-styled.swal2-confirm {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
  background-color: transparent;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-border-radius
  );

  &:hover,
  &:focus {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd) !important;
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
}

.swal2-confirm.btn.bg-gradient-success {
  margin-left: 10px;
  margin-right: 10px;
}

.swal2-image {
  border-radius: $border-radius-lg;
  margin-bottom: 0;
}

.swal2-input {
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}
