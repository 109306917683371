.bg-primary-soft {
  background: rgba($primary-gradient, 0.03);
}

.bg-info-soft {
  background: rgba($info-gradient, 0.03);
}

.bg-success-soft {
  background: rgba($success-gradient, 0.03);
}

.bg-warning-soft {
  background: rgba($warning-gradient, 0.03);
}

.bg-danger-soft {
  background: rgba($danger-gradient, 0.03);
}
