// Badge
$badge-sm-padding: 0.45em 0.775em !default;
$badge-sm-font-size: 0.65em !default;
$badge-md-padding: 0.65em 1em !default;
$badge-lg-padding: 0.85em 1.375em !default;
$badge-inline-margin-right: 0.625rem !default;
$badge-inline-span-top: 2px !default;
$badge-btn-margin: 0.5rem !default;

// Badge Circle
$badge-circle-border-radius: 50% !default;
$badge-circle-width: 1.25rem !default;
$badge-circle-height: 1.25rem !default;
$badge-circle-font-size: 0.75rem !default;
$badge-circle-font-weight: 600 !default;

$badge-circle-md-width: 1.5rem !default;
$badge-circle-md-height: 1.5rem !default;

$badge-circle-lg-width: 2rem !default;
$badge-circle-lg-height: 2rem !default;

//Badge Dot
$badge-dot-icon-width: 0.375rem !default;
$badge-dot-icon-height: 0.375rem !default;
$badge-dot-icon-radius: 50% !default;
$badge-dot-icon-margin-right: 0.375rem !default;

$badge-dot-md-icon-width: 0.5rem !default;
$badge-dot-md-icon-height: 0.5rem !default;

$badge-dot-lg-icon-width: 0.625rem !default;
$badge-dot-lg-icon-height: 0.625rem !default;

//Badge Floating
$badge-floating-top: -50% !default;
$badge-floating-border: 3px !default;
$badge-floating-transform: translate(147%, 50%) !default;
